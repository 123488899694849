//TODO: refactor and rename namespace
e4.ecom.cartConfirm = {
    timeout: null,
    init: function () {
        if ( jQuery( '.js-e-product-form' ).length > 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-cart-confirm-lastadded' );
            } );
            e4.ecom.cartConfirm.bindEvents();
        }
    },
    bindEvents: function () {
        //TODO: Move to ecom.cart
        jQuery( '.js-e-product-form' ).on( 'submit', function ( event ) {
            e4.ecom.cartConfirm.submit( event );
        } );
        jQuery( '#js-e-cart-confirm' ).on( 'click', function ( event ) {
            if ( jQuery( event.target ).closest( '.js-e-cart-confirm-close' ).length === 1 ) {
                event.preventDefault();
                e4.ecom.cartConfirm.hide();
            }
        } ).on( 'mouseover', e4.ecom.cartConfirm.clearTimeout ).on( 'mouseleave', {
            timeout: Math.round( e4.settings.ecom.cart.confirmMsgHideDelay / 3 )
        }, e4.ecom.cartConfirm.setTimeout );
        jQuery( '.js-e-header' ).on( 'hidden.e4.header shown.e4.header', function () {
            e4.ecom.cartConfirm.setPosition();
        } );
        jQuery( document ).on( 'click', function ( event ) {
            if ( jQuery( event.target ).closest( '#js-e-cart-confirm' ).length === 0 ) {
                e4.ecom.cartConfirm.hide();
            }
        } );
    },
    show: function () {
        e4.ecom.cartConfirm.clearTimeout();
        e4.ecom.cartConfirm.setPosition();
        jQuery( '#js-e-cart-confirm' ).removeClass( 'is-hidden' );
        e4.ecom.cartConfirm.setTimeout();
    },
    hide: function () {
        e4.ecom.cartConfirm.clearTimeout();
        e4.ecom.cartConfirm.setPosition( {
            top: ''
        } );
        jQuery( '#js-e-cart-confirm' ).addClass( 'is-hidden' );
    },
    setPosition: function ( objPosition ) {
        var jCartMsg             = jQuery( '#js-e-cart-confirm' ),
            jHeader              = jQuery( '.js-e-header' ),
            jNavItemCart         = jHeader.find( '.js-e-nav-global,.js-e-nav-utilities-primary' ).find( '.js-e-nav-item-cart' ).filter( ':visible' ),
            objCartMsgPosition   = objPosition || {},
            objReferencePosition = {};
        if ( jCartMsg.length === 1 ) {
            if ( jQuery.isEmptyObject( objCartMsgPosition ) === true ) {
                if ( jNavItemCart.length === 1 ) {
                    objReferencePosition = jNavItemCart.get( 0 ).getBoundingClientRect();
                    objCartMsgPosition   = {
                        top: jHeader.hasClass( 'is-hidden' ) === true ? '' : Math.floor( objReferencePosition.bottom ),
                        right: Math.floor( jQuery( window ).width() - objReferencePosition.right ),
                        left: '',
                        width: ''
                    }
                }
                else {
                    objReferencePosition = jHeader.get( 0 ).getBoundingClientRect();
                    objCartMsgPosition   = {
                        top: jHeader.hasClass( 'is-hidden' ) === true ? '' : Math.floor( objReferencePosition.bottom ),
                        right: 0,
                        left: 0,
                        width: 'auto'
                    };
                }
            }
        }
        jCartMsg.css( objCartMsgPosition );
    },
    setTimeout: function ( event ) {
        //TODO: check for mobile
        var timeout = e4.settings.ecom.cart.confirmMsgHideDelay;
        if ( event !== undefined && event.data !== undefined ) {
            timeout = Math.round( event.data.timeout || timeout );
        }
        e4.ecom.cartConfirm.timeout = setTimeout( e4.ecom.cartConfirm.hide, timeout );
    },
    clearTimeout: function () {
        if ( e4.ecom.cartConfirm.timeout ) {
            e4.ecom.cartConfirm.timeout = clearTimeout( e4.ecom.cartConfirm.timeout );
        }
    },
    submit: function ( event ) {
        event.preventDefault();
        //e4.ecom.cartConfirm.loadingShow();
        var jForm = jQuery( event.target ),
            jqXHR;
        jForm.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
        jqXHR = jQuery.ajax( {
            type: 'POST',
            url: e4.settings.ecom.cart.url,
            data: jForm.serialize(),
            dataType: 'json',
            context: event.target
        } );
        jqXHR.done( function ( data ) {
            e4.ecom.cartConfirm.success( data );
        } );
        jqXHR.fail( function ( data, textStatus, errorThrown ) {
            e4.ecom.cartConfirm.error( data, textStatus, errorThrown );
        } );
        jqXHR.always( function () {
            jForm.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
        } );
    },
    success: function ( data ) {
        jQuery( '#js-e-cart-confirm' ).find( '.js-e-cart-confirm-lastadded' ).html( e4.handlebars.tmpl.cartConfirmLastadded( data ) );
        e4.ecom.cartConfirm.show();
        e4.ecom.cart.updateQuantity( data.cart.totalProductQuantity );
    },
    error: function ( data, textStatus, errorThrown ) {
        console.log( 'fail', data, textStatus, errorThrown );
    }
};

