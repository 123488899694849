e4.ecom.cart = {
    init: function () {
        if ( jQuery( '.js-e-cart-form' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'loop compare' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-cart-orderline #js-e-handlebars-tmpl-cart-summery' );
                e4.ecom.cart.load();
            } );
            e4.ecom.cart.bindEvents();
        }
        else if ( jQuery( '.js-e-cart-quantity' ).length > 0 ) {
            e4.ecom.cart.updateQuantity();
        }
    },
    bindEvents: function () {
        e4.ecom.cart.updateOrderlineTimeout = null;
        jQuery( '.js-e-cart-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
        } ).on( 'focus', '.js-e-orderline-quantity', function () {
            jQuery( this ).select();
        } ).on( 'input', '.js-e-orderline-quantity', function ( event ) {
            if ( e4.ecom.cart.updateOrderlineTimeout !== null ) {
                clearTimeout( e4.ecom.cart.updateOrderlineTimeout );
                e4.ecom.cart.updateOrderlineTimeout = null;
            }
            e4.ecom.cart.updateOrderlineTimeout = setTimeout( function () {
                if ( parseInt( event.target.value ) !== 0 && event.target.value !== '' ) {
                    e4.ecom.cart.orderlines.update( event.target );
                }
            }, e4.settings.ecom.cart.updateOrderlineDelay );
        } ).on( 'click', '.js-e-orderline-update', function ( event ) {
            event.preventDefault();
            e4.ecom.cart.orderlines.update( this );
        } ).on( 'click', '.js-e-orderline-delete', function ( event ) {
            event.preventDefault();
            e4.ecom.cart.orderlines.delete( this );
        } );
    },
    ajax: function ( objAjaxSettings, fnCallback ) {
        var jqXHR = jQuery.ajax( jQuery.extend( {
            url: e4.settings.ecom.cart.url,
            method: 'POST',
            dataType: 'json',
            cache: false
        }, objAjaxSettings ) );
        // console.log(jQuery.extend( {
        //     url: e4.settings.ecom.cart.url,
        //     method: 'POST',
        //     dataType: 'json',
        //     cache: false
        // }, objAjaxSettings ));
        jqXHR.done( function ( data ) {
            if ( typeof fnCallback === 'function' ) {
                fnCallback( data );
            }
        } );
        jqXHR.fail( function ( data, textStatus, errorThrown ) {
            console.error( data, textStatus, errorThrown );
        } );
        // jqXHR.always( function () {} );
    },
    load: function () {
        var jCart = jQuery( '.js-e-cart' );
        e4.ecom.cart.ajax( {
            method: 'GET',
            beforeSend: function () {
                jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
            }
        }, function ( data ) {
            e4.ecom.cart.updateContent( data );
            e4.ecom.cart.updateSummery( data );
            e4.ecom.cart.updateQuantity( data.cart.totalProductQuantity );
            jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );

        } );
    },
    updateContent: function ( data ) {
        jQuery( '.js-e-cart-orderlines' ).html( e4.handlebars.tmpl.cartOrderline( data ) );
        e4.pageLoading.manualOverride( '.js-e-cart-orderlines' );
    },
    updateSummery: function ( data ) {
        jQuery( '.js-e-cart-summery' ).html( e4.handlebars.tmpl.cartSummery( data ) );
        e4.pageLoading.manualOverride( '.js-e-cart-summery' );
    },
    updateQuantity: function ( intQuantity ) {
        if ( intQuantity === 0 ) {
            jQuery( '.js-e-cart-quantity' ).html( '' );
        }
        else if ( isNaN( parseInt( intQuantity ) ) === true ) {
            e4.ecom.cart.ajax( {
                method: 'GET',
            }, function ( data ) {
                e4.ecom.cart.updateQuantity( data.cart.totalProductQuantity );
            } );
        }
        else {
            jQuery( '.js-e-cart-quantity' ).html( intQuantity );
        }
    },
    orderlines: {
        update: function ( domElm ) {
            var jCart = jQuery( '.js-e-cart' ),
                jForm = jQuery( domElm ).closest( '.js-e-cart-form' ),
                jOrderline = jQuery( domElm ).closest( '.js-e-orderline' ),
                intOrderlineQuantity = parseInt( jOrderline.find( '.js-e-orderline-quantity' ).val() );
            if ( intOrderlineQuantity !== 0 ) {
                jForm.find( '.js-e-cart-form-cartcmd' ).val( 'updateorderlines' );
                // jForm.find( '.js-e-cart-form-redirect' ).val( e4.settings.ecom.cart.url );
                e4.ecom.cart.ajax( {
                    data: jForm.serialize(),
                    beforeSend: function () {
                        jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                    }
                }, function ( data ) {
                    e4.ecom.cart.updateContent( data );
                    e4.ecom.cart.updateSummery( data );
                    e4.ecom.cart.updateQuantity( data.cart.totalProductQuantity );
                    jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
                    jQuery( '#' + jOrderline.attr( 'id' ) ).find( '.js-e-orderline-quantity' ).focus();
                } );
            }
            else {
                e4.ecom.cart.orderlines.delete( domElm );
            }

        },
        delete: function ( domElm ) {
            var jCart = jQuery( '.js-e-cart' );
            e4.ecom.cart.ajax( {
                data: {
                    key: jQuery( domElm ).closest( '.js-e-orderline' ).attr( 'id' ),
                    CartCmd: 'delorderline',
                    Redirect: e4.settings.ecom.cart.url
                },
                beforeSend: function () {
                    jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                }
            }, function ( data ) {
                e4.ecom.cart.updateContent( data );
                e4.ecom.cart.updateSummery( data );
                e4.ecom.cart.updateQuantity( data.cart.totalProductQuantity );
                jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
            } );
        }
    },
    add:{
        submit: function ( event ) {
            event.preventDefault();
            //e4.ecom.cartConfirm.loadingShow();
            var jForm = jQuery( event.target ),
                jqXHR;
            jForm.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
            jqXHR = jQuery.ajax( {
                type: 'POST',
                url: e4.settings.ecom.cart.url,
                data: jForm.serialize(),
                dataType: 'json',
                context: event.target
            } );
            jqXHR.done( function ( data ) {
                e4.ecom.cartConfirm.success( data );
            } );
            jqXHR.fail( function ( data, textStatus, errorThrown ) {
                e4.ecom.cartConfirm.error( data, textStatus, errorThrown );
            } );
            jqXHR.always( function () {
                jForm.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
            } );
        },
        success: function ( data ) {
            jQuery( '#js-e-cart-confirm' ).find( '.js-e-cart-confirm-lastadded' ).html( e4.handlebars.tmpl.cartConfirmLastadded( data ) );
            e4.ecom.cartConfirm.show();
            e4.ecom.cart.updateQuantity( data.cart.totalProductQuantity );
        },
        error: function ( data, textStatus, errorThrown ) {
            console.log( 'fail', data, textStatus, errorThrown );
        }
    }
};

