e4.nav = {
    init: function () {
        e4.nav.global.init();
        e4.nav.local.horizontal.init();
        e4.nav.local.vertical.init();
        e4.nav.util.init();
    },
    global: {
        init: function () {
            e4.nav.global.bindEvents();
        },
        bindEvents: function () {
            var jNav = jQuery( '.js-e-nav' ),
                jHeader = jQuery( '.js-e-header' ),
                jNavToggle = jQuery( '.js-e-nav-toggle' );
            jNavToggle.on( 'click', function ( event ) {
                event.preventDefault();
                event.stopPropagation();
                jNav.modal( 'toggle' );
            } );
            jNav.on( 'swipeleft', function () {
                jNav.modal( 'hide' );
            } );
            jNav.on( 'show.bs.modal', function () {
                // TODO: Lav util setting/class
                jHeader.addClass( 'reset-transform-containing-block' );
            } );
            jNav.on( 'shown.bs.modal', function () {
                e4.nav.global.revealActive();
            } );
            jNav.on( 'hide.bs.modal', function () {
                // TODO: Lav util setting/class
                jHeader.removeClass( 'reset-transform-containing-block' );
            } );
            jNav.on( 'hidden.bs.modal', function () {
                //TODO: Lægges ud i funktion.
                var jNav = jQuery( '.js-e-nav' );
                jNav.scrollTop( 0 );
                jNav.removeAttr( 'style' );
                jNav.find( '.js-e-nav-global' ).find( '.nav-link.is-inpath' ).filter( '[data-collapse-target]' ).each( function () {
                    jQuery( this.getAttribute( 'data-collapse-target' ) ).removeClass( 'show' );
                } );
            } );
            jNav.on( 'click', 'a', function ( event ) {
                //TODO: Lægges ud i funktion.
                var jThis = jQuery( this );
                if ( jThis.is( '.show [data-toggle="dropdown"]' ) === true ) {
                    event.stopPropagation();
                }
                if ( jThis.is( '.show [data-collapse-toggle="true"]' ) === true ) {
                    event.preventDefault();
                    jQuery( this.getAttribute( 'data-collapse-target' ) ).collapse( 'toggle' );
                }
            } );
        },
        revealActive: function () {
            var jNav = jQuery( '.js-e-nav' ),
                jNavItemActive = jNav.find( '.nav-link.is-active' ).last(),
                jNavItemActiveParent = jNavItemActive.closest( '.nav' ).closest( '.nav-item' ),
                jOffsetElement = jNavItemActiveParent,
                intScrollTarget = 0;
            jQuery( '.js-e-nav-global' ).find( '.nav-link.is-inpath' ).filter( '[data-collapse-target]' ).each( function () {
                jQuery( this.getAttribute( 'data-collapse-target' ) ).addClass( 'show' );
            } );
            if ( jNavItemActive.length === 1 && e4.util.isInViewport( jNavItemActive ) === false ) {
                setTimeout( function () {
                    // Hvis parent item ikke findes, eller er for høj til viewport, sættes offset element til det aktive item.
                    if ( jNavItemActiveParent.length === 0 || jNavItemActiveParent.height() > jNav.height() ) {
                        jOffsetElement = jNavItemActive;
                    }
                    // Beregn relativt offset til containeren.
                    intScrollTarget = jOffsetElement.offset().top - jNav.offset().top;
                    jNav.animate( {
                        scrollTop: intScrollTarget
                    }, Math.floor( intScrollTarget * e4.settings.nav.revealActive.speed ), e4.settings.nav.revealActive.easing );

                }, e4.settings.nav.revealActive.delay );
            }
        }
    },
    local: {
        horizontal: {
            init: function () {
                var jNavLocalHorizontal = jQuery( '.js-e-nav-local-horizontal.is-sticky' );
                if ( jNavLocalHorizontal.length === 1 ) {
                    //e4.nav.local.horizontal.domElm = jNavLocalHorizontal.get( 0 );
                    e4.nav.local.horizontal.isRAF = false;
                    jNavLocalHorizontal.removeClass( 'is-sticky' );
                    e4.nav.local.horizontal.intialOffsetTop = jNavLocalHorizontal.offset().top;
                    jNavLocalHorizontal.addClass( 'is-sticky' );
                    e4.nav.local.horizontal.bindEvents();
                    e4.nav.local.horizontal.toggleSticky();
                }
            },
            bindEvents: function () {
                jQuery( window ).on( 'scroll', function () {
                    if ( e4.nav.local.horizontal.isRAF === false ) {
                        window.requestAnimationFrame( function () {
                            e4.nav.local.horizontal.toggleSticky();
                            e4.nav.local.horizontal.isRAF = false;
                        } );
                    }
                    e4.nav.local.horizontal.isRAF = true;
                } );
            },
            toggleSticky: function () {
                //var intOffsetTop = e4.nav.local.horizontal.domElm.getBoundingClientRect().top;
                if ( jQuery( window ).scrollTop() > e4.nav.local.horizontal.intialOffsetTop ) {
                    jQuery( '.js-e-nav-local-horizontal' ).addClass( 'is-stuck' );
                }
                else {
                    jQuery( '.js-e-nav-local-horizontal' ).removeClass( 'is-stuck' );
                }
            }
        },
        vertical: {
            init: function () {
                var jNavLocalVertical = jQuery( '.js-e-nav-local-vertical' );
                if ( jNavLocalVertical.length === 1 ) {
                    if ( jNavLocalVertical.is( '.is-sticky' ) === true ) {
                        if ( jNavLocalVertical.outerHeight( true ) > jQuery( window ).height() ) {
                            jNavLocalVertical.removeClass( 'is-sticky' );
                        }
                        else {
                            //e4.nav.local.vertical.domElm = jNavLocalVertical.get( 0 );
                            e4.nav.local.vertical.isRAF = false;
                            jNavLocalVertical.removeClass( 'is-sticky' );
                            e4.nav.local.vertical.intialOffsetTop = jNavLocalVertical.offset().top;
                            jNavLocalVertical.addClass( 'is-sticky' );
                            e4.nav.local.vertical.bindEvents();
                            e4.nav.local.vertical.toggleSticky();
                        }
                    }
                    e4.nav.local.vertical.setContentHeight();
                }
            },
            bindEvents: function () {
                jQuery( window ).on( 'scroll', function () {
                    if ( e4.nav.local.vertical.isRAF === false ) {
                        window.requestAnimationFrame( function () {
                            e4.nav.local.vertical.toggleSticky();
                            e4.nav.local.vertical.isRAF = false;
                        } );
                    }
                    e4.nav.local.vertical.isRAF = true;
                } );
            },
            toggleSticky: function () {
                var intWindowScrollTop = jQuery( window ).scrollTop(),
                    intContentMainBottom = Math.floor( jQuery( '.e-content-main' ).offset().top + jQuery( '.e-content-main' ).height() ),
                    intNavLocalVerticalHeight = Math.ceil( jQuery( '.js-e-nav-local-vertical' ).height() );
                //var intOffsetTop = e4.nav.local.vertical.domElm.getBoundingClientRect().top;
                if ( intWindowScrollTop > e4.nav.local.vertical.intialOffsetTop ) {
                    jQuery( '.js-e-nav-local-vertical' ).addClass( 'is-stuck' );
                    if ( intWindowScrollTop + intNavLocalVerticalHeight >= intContentMainBottom ) {
                        jQuery( '.js-e-nav-local-vertical' ).addClass( 'has-reached-bottom' );

                    }
                    else {
                        jQuery( '.js-e-nav-local-vertical' ).removeClass( 'has-reached-bottom' );
                    }
                }
                else {
                    jQuery( '.js-e-nav-local-vertical' ).removeClass( 'is-stuck' );
                }
            },
            setContentHeight: function () {
                jQuery( '.e-content-main' ).css( 'min-height', function () {
                    var intNavLocalHeight = parseInt( jQuery( '.js-e-nav-local-vertical' ).outerHeight( true ) ) || 0,
                        intContentPaddingTop = parseInt( window.getComputedStyle( this ).getPropertyValue( 'padding-top' ) ) || 0,
                        intContentPaddingBottom = parseInt( window.getComputedStyle( this ).getPropertyValue( 'padding-bottom' ) ) || 0;
                    return intContentPaddingTop + intNavLocalHeight + intContentPaddingBottom;
                } );
            }
        }
    },
    util: {
        init: function () {
            jQuery( '.js-e-nav-pageicon-language' ).each( function () {
                var jThis = jQuery( this ),
                    arrClassList = jThis.parent().attr( 'class' ).split( ' ' ),
                    strLangISO = '';
                for ( var c = 0; c < arrClassList.length; c++ ) {
                    if ( arrClassList[ c ].indexOf( 'icon-flag-' ) === 0 ) {
                        strLangISO = arrClassList[ c ].split( '-' )[ 2 ];
                        jThis.css( 'background-image', 'url(' + e4.settings.nav.flagCDN + strLangISO + '.svg)' );
                    }
                }
            } );
        }
    }
};

